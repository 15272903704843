import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Form from "../Form/Form";
const ContactForm = () => {
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="../../images/globe.jpg"
            alt=""
          />
        </div>
      </div>
      <div
        className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-16 lg:grid lg:grid-cols-2"
        style={{ minHeight: "40rem" }}
      >
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0 min-h-72">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              Let's connect and <br /> solve IoT Together{" "}
              <span style={{ color: "#66a3ff" }}>🌐</span>
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              {`We’d love to hear from you! Tell us about your use case and we can help you get started embedding AI inside your products and services.`}
            </p>
            <Form />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
