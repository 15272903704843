import React from "react";
import { useForm, ValidationError } from "@formspree/react";

const Form = () => {
  const [state, handleSubmit] = useForm("xjvlbkwv");

  if (state.succeeded) {
    window.scrollTo(0, 0);
    return (
      <div className="flex items-center mt-8">
        <svg
          height={45}
          width={45}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          enable-background="new 0 0 64 64"
        >
          <path
            d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
            fill="#7cb342"
          />
        </svg>
        <p className="ml-2 text-lg text-gray-700">
          We've recieved your submission. Someone from our team will be in touch
          with you shortly!
        </p>
      </div>
    );
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
    >
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="name"
            id="name"
            autoComplete="given-name"
            className="block w-full shadow-sm sm:text-sm focus:ring-primaryDarker focus:border-primaryDarker border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className="block w-full shadow-sm sm:text-sm focus:ring-primaryDarker focus:border-primaryDarker border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <div className="flex justify-between">
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-700"
          >
            Phone
          </label>
        </div>
        <div className="mt-1">
          <input
            type="text"
            name="phone"
            id="phone"
            autoComplete="tel"
            aria-describedby="phone-description"
            className="block w-full shadow-sm sm:text-sm focus:ring-primaryDarker focus:border-primaryDarker border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="sm:col-span-2">
        <div className="flex justify-between">
          <label
            htmlFor="how-can-we-help"
            className="block text-sm font-medium text-gray-700"
          >
            How can we help you?
          </label>
        </div>
        <div className="mt-1">
          <textarea
            id="how-can-we-help"
            name="how-can-we-help"
            aria-describedby="how-can-we-help-description"
            rows={4}
            className="block p-4 w-full shadow-sm sm:text-sm focus:ring-primaryDarker focus:border-primaryDarker border border-gray-300 rounded-md"
            defaultValue={""}
          />
        </div>
      </div>
      <div className="text-right sm:col-span-2">
        <button
          type="submit"
          disabled={state.submitting}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-md text-white bg-secondaryDarker hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryDarker"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default Form;
